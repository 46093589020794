body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

img {
  object-fit: contain;
  max-width: 100%;
}

ul {
  margin: 0;
  padding: 0;
}
